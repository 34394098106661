import React, { useEffect } from 'react'

import 'twin.macro'

import SEO from 'components/shared/seo'
import { useInspiration } from 'content-queries/mx/inspiration'
import fireEvent from 'context/tracking/events'
import SignUpHero from 'mx/components/sign-up-hero'
import { Press } from 'mx/homepage/press'
import Layout from 'mx/layout'
import { FormContactSource } from 'types/crm'
import { resourcesUrls } from 'views/utils'

const InspirationPage = ({ path }: { path: string }) => {
  const { data } = useInspiration()

  const [
    {
      node: {
        sectionModules: [signUp],
      },
    },
  ] = data

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Inspiration Guide',
    })
    fireEvent({
      type: 'guide_download_started',
      guideName: 'Inspiration Guide',
    })
  }, [])

  return (
    <Layout path={path}>
      <SignUpHero
        contactSource={FormContactSource.SIGNUP_INSPIRATION_GUIDE}
        data={signUp}
        guideName={'Inspiration Guide'}
        resourceUrl={resourcesUrls.inspirationGuide}
      />
      <Press />
    </Layout>
  )
}

export default InspirationPage

export const Head = () => (
  <SEO
    description="Over 100 ready-made Element Boards make it easy to find the exact materials for the style you’re after."
    title="Inspiration Guide"
  />
)
