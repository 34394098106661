import { useState, useEffect } from 'react'

import { useIntersection } from 'react-use'

function useDisplay(
  intersectionRef: React.MutableRefObject<any>,
  threshold = 1,
) {
  const [showIt, setShowIt] = useState(false)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: threshold,
  })

  useEffect(() => {
    if (!showIt) {
      if (intersection && intersection?.intersectionRatio >= threshold) {
        setShowIt(true)
      }
    }
  }, [intersection?.intersectionRatio])

  return showIt
}

export default useDisplay
