import React from 'react'

import 'twin.macro'
import { motion } from 'framer-motion'
import tw from 'twin.macro'

import { useHomePressSection } from 'content-queries/mx/home-press'

import useDisplay from '../hooks/useDisplay'
import { pressContainer, pressItems } from '../motion-variants'
import { Section } from '../section'

type pressInfo = {
  description: string
  id: string
  title: string
  file: {
    url: string
  }
}

const Press = ({ hideQuotes = false }: { hideQuotes?: boolean }) => {
  const { data } = useHomePressSection()
  const [
    {
      node: {
        sectionModules: [pressInfo],
      },
    },
  ] = data

  const intersectionRef = React.useRef(null)
  const showIt = useDisplay(intersectionRef)

  return (
    <Section tw="mt-44" css={hideQuotes && tw`!mt-24`} padding="none">
      <div ref={intersectionRef} tw="flex flex-wrap relative md:(px-6)">
        <motion.div
          variants={pressContainer}
          initial="hidden"
          animate={showIt ? 'show' : 'hidden'}
          className="scroll-snap-x"
          tw="flex pl-4 pb-6 w-full md:(pl-0)"
        >
          {pressInfo?.images?.map((press: pressInfo) => {
            return (
              <motion.div
                key={press.id}
                variants={pressItems}
                tw="w-1/2 flex flex-col shrink-0 items-start px-2 relative md:(w-1/5 px-4)"
              >
                <div tw="h-12 flex items-center place-self-start md:(h-16)">
                  <img src={press?.file.url} alt={press.title} />
                </div>
                {!hideQuotes && (
                  <p tw="text-gray-600 font-light text-sm leading-normal text-left max-w-xs self-start">
                    {press.description}
                  </p>
                )}
              </motion.div>
            )
          })}
        </motion.div>
      </div>
    </Section>
  )
}

export { Press }
