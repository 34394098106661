import { graphql, useStaticQuery } from 'gatsby'

export const useInspiration = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "3eQI4ToRqxMoF3PQmZdiHj" } }
      ) {
        data: edges {
          node {
            sectionModules {
              contentSlots {
                actionText
                description
                markdown {
                  raw
                }
                photos {
                  title
                  file {
                    url
                  }
                }
                slotTitle
              }
            }
          }
        }
      }
    }
  `)

  return content
}
